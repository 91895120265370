import { render, staticRenderFns } from "./MediaList.vue?vue&type=template&id=dd6fd154&scoped=true&"
import script from "./MediaList.vue?vue&type=script&lang=ts&"
export * from "./MediaList.vue?vue&type=script&lang=ts&"
import style0 from "./MediaList.vue?vue&type=style&index=0&id=dd6fd154&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd6fd154",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
