















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import GridList from "@/components/lists/GridList/index.vue";
import VideoCard from "./VideoCard.vue";
import FileCard from "./FileCard.vue";
import ImgCard from "./ImgCard.vue";
import { EMediaType } from "@/models/enums";

@Component({
  name: "MediaList",
  components: {
    GridList,
    VideoCard,
    FileCard,
    ImgCard,
  },
})
export default class extends Vue {
  @Prop({ required: true, default: [] })
  public readonly items!: [];

  @Prop({ required: false, default: 2 })
  public readonly previewCount!: number;

  $refs!: {
    list: GridList;
  };

  private showMore = false;

  private collapsedHeight = 0;

  private expandedHeight = 0;

  private isMobile = false;

  onResize() {
    this.isMobile =
      window.innerWidth <= this.$vuetify.breakpoint.thresholds.sm - 1;
  }

  mounted() {
    const list = this.$refs.list;
    if (list && list.$children.length > 0) {
      const previewCount =
        this.previewCount > list.$children.length
          ? list.$children.length
          : this.previewCount;

      list.$children.forEach((slot: Vue, index: number) => {
        if (index < previewCount) {
          this.collapsedHeight += slot.$el.clientHeight + 10;
        }
        this.expandedHeight += slot.$el.clientHeight + 10;
      });
    }
  }

  get listItems() {
    return this.items.filter((x: any) => this.isValidMediaType(x.type));
  }

  isValidMediaType(value: EMediaType) {
    switch (value) {
      case EMediaType.Image:
      case EMediaType.Text:
      case EMediaType.Presentation:
      case EMediaType.Video:
      case EMediaType.JobVideo:
        return true;
      default:
        return false;
    }
  }
}
